import { createApp }  from 'vue'
import router         from '@/router'
import App            from '@/App.vue'
import VueCookies     from 'vue-cookies'
import VueTelInput    from 'vue-tel-input'
import i18n           from '@/configs/i18n'
import VueHtmlToPaper from '@/commons/VueHtmlToPaper'

const app = createApp(App)

app.use(i18n)
app.use(router)
app.use(VueTelInput)
app.use(VueHtmlToPaper)
app.use(VueCookies, { expires: '7d'})

app.config.productionTip = false

app.mount('#app')
