export const cookies = {

    methods: {
        setCookie(name, value) {
            if (value !== null && value !== '')
                this.$cookies.set(name, value)
        },

        getCookie(name) {
            return this.$cookies.get(name)
        },
    }

}