<template>

  <div class="nk-footer nk-footer-fluid bg-lighter">
    <div class="container-xl">

      <div class="nk-footer-wrap">

        <div class="nk-footer-copyright"> {{ $t('© 2021 Paayoor IT. All Rights Reserved.') }}</div>

        <div class="nk-footer-links">
          <ul class="nav nav-sm">

            <li class="nav-item"><a :href="`${HELP_CENTER_APP}/terms-policy`" target="_blank" class="nav-link text-capitalize">{{ $t('Terms of use') }}</a></li>

            <li class="nav-item"><a :href="`${HELP_CENTER_APP}/terms-policy`" target="_blank" class="nav-link text-capitalize">{{ $t('Privacy Policy') }}</a></li>

            <li class="nav-item"><a :href="`${HELP_CENTER_APP}/terms-policy`" target="_blank" class="nav-link text-capitalize">{{ $t('Help') }}</a></li>

            <li class="nav-item dropup">

              <language-list-component />

            </li>

          </ul>
        </div>

      </div>

    </div>
  </div>

</template>

<script>

  import LanguageListComponent from '@/components/LanguageListComponent'

  export default {

    name: 'FooterComponent',

    components:{
      LanguageListComponent
    },

    data: () => ({
      HELP_CENTER_APP: process.env.VUE_APP_HELP_CENTER_APP
    })

  }

</script>
