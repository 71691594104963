import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'transfer',
    component: () => import('@/views/TransferView')
  },
  {
    path: '/:token/pending',
    name: 'transfer-pending',
    component: () => import('@/views/PendingView')
  },
  {
    path: '/:token/succeeded',
    name: 'transfer-succeeded',
    component: () => import('@/views/SucceededView')
  },
  {
    path: '/:token/failed',
    name: 'transfer-failed',
    component: () => import('@/views/FailedView')
  },
  {
    path: '/:token/canceled',
    name: 'transfer-canceled',
    component: () => import('@/views/CanceledView')
  },
  {
    path: '/:token/invalid',
    name: 'transfer-invalid',
    component: () => import('@/views/InvalidView')
  },
  {
    path: '/:token/error',
    name: 'transfer-error',
    component: () => import('@/views/ErrorView')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/errors/ErrorView')
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('@/views/errors/NotFoundView')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  mode: 'history',
  routes,
})

router.afterEach(() => {

  if(! process.env.VUE_APP_DEBUG)
    console.clear()

})

export default router