import axios  from 'axios'
import router from '@/router'

const axiosApiInstance = axios.create()

axiosApiInstance.interceptors.request.use(
    config => {

        config.withCredentials = true

        config.baseURL = process.env.VUE_APP_USER_AUTH_API_DOMAIN

        config.headers = {
            'Accept':        'application/json',
            'Content-Type':  'application/json'
        }

        return config;

    }, error => {

        Promise.reject(error)

    })

axiosApiInstance.interceptors.response.use(
    response => {

        return response

        }, async function (error) {

            if(error.response.status === 401)
                window.location.href = process.env.VUE_APP_AUTH_APP

            if(! [403, 422].includes(error.response.status))
                await router.push('/error')

        return Promise.reject(error)

    })

export default axiosApiInstance
