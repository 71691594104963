<template>

  <!-- wrap @s -->
  <div class="nk-wrap">

    <div class="nk-content nk-content-fluid">

      <!-- content @s -->
      <router-view v-slot="{ Component }">
        <transition appear name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
      <!-- content @e -->

    </div>

    <!-- footer @s -->
    <footer-component />
    <!-- footer @e -->

  </div>
  <!-- wrap @e -->

</template>

<script>

  import FooterComponent from '@/components/FooterComponent'

  export default {

    name: 'App',

    components:{
      FooterComponent,
    }
  }

</script>
