export const dropdown = {

    data(){
        return {

            status: false

        }
    },

    methods: {

        isDropdownActive() {

            return this.status

        },

        enableDropdown() {

            this.status = true

        },

        disableDropdown() {

            this.status = false

        },

        toggleDropdown() {

            this.status = ! this.isDropdownActive()

        }

    }

}