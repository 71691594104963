export const locale = {

    methods: {

        setLocale(locale){

            this.$i18n.locale = locale

            this.$cookies.set('locale', locale)


        }

    }

}