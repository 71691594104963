import { createI18n } from 'vue-i18n'
import VueCookies     from 'vue-cookies'
import messages       from '../commons/locales.json'

let defaultLanguageCode = VueCookies.get('locale')
    ? VueCookies.get('locale')
    : (process.env.VUE_APP_I18N_LOCALE ? process.env.VUE_APP_I18N_LOCALE : 'fr')

const VueI18nInstance = new createI18n({
  locale: defaultLanguageCode,
  fallbackLocale: defaultLanguageCode,
  messages: messages
})

export default VueI18nInstance
