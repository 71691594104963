import CoreUserAuthApi from '@/api/CoreUserAuth'

export default {

    async getLanguages() {

        return await CoreUserAuthApi.get('api/language')
            .then(response => {

                return response.data.languages

            }).catch(() => {

                return []

            })

    }

}