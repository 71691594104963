<template>

  <div v-if="loading">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </div>

  <div class="dropdown" v-else>

    <a class="dropdown-toggle dropdown-indicator has-indicator nav-link" data-toggle="dropdown" @click.prevent="toggleDropdown()">
      <img :src="languageFlagSrc(currentLanguage)" alt="" class="language-flag">
      <small>{{ $t(capitalize(currentLanguage.slug)) }}</small>
    </a>

    <div :class="['dropdown-menu dropdown-menu-sm dropdown-menu-right', { 'show': isDropdownActive() }]">
      <ul class="language-list">

        <li v-for="(language, index) in languages" :key="index">
          <a href="#" @click.prevent="setLocale(language.code);disableDropdown()" :class="['language-item', { 'selected': language.code === currentLanguage.code }]">
            <img :src="languageFlagSrc(language)" alt="" class="language-flag">
            <span class="language-name">{{ $t(capitalize(language.slug)) }}</span>
          </a>
        </li>

      </ul>
    </div>

  </div>

</template>

<script>

  import {
    state,
    locale,
    dropdown
  } from '@/mixins'

  import LanguageRepository from '@/repositories/LanguageRepository'

  export default {

    name: 'LanguageListComponent',

    mixins: [
      state,
      locale,
      dropdown
    ],

    data(){
      return {
        languages: []
      }
    },

    computed:{
      currentLanguage: function () {
          return this.languages.filter((language) => language.code === this.$i18n.locale)[0]
      }
    },

    async mounted() {
      this.languages = await LanguageRepository.getLanguages()

      this.loading = false
    },

    methods: {
      capitalize(s){
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      },

      languageFlagSrc(language){
        return '/assets/images/flags/' + language.slug + '.png'
      }
    }

  }

</script>

<style>

  .language-item.selected {
    color: #3c4d62;
    background: #ebeef2;
  }

</style>