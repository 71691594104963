export const state = {

    data(){
        return {

            loading: true,
            error: false

        }
    },

    methods: {

        isLoading() {

            return this.loading

        },

        setLoading() {

            this.loading = true

        },

        hasError() {

            return this.error

        },

        setError(){

            this.error = true

        }

    }

}